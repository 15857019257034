import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

class Applewood extends React.Component {

    render() {
        return (
            <Layout metaTitle="Applewood Smoked Bacon"
                metaDescription="We smoke our thick-cut Applewood Smoked Bacon as long as needed to work the light, fruity & slightly sweet aroma of apple wood deep into the meat. Buy today!"
                metaKeywords="applewood smoked bacon">
                <article class="page-section with-placeholder ">
                    <div class="image"><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/productpagedetailimagewbb.jpg'} alt="Applewood Smoked Bacon" /></div>
                    <div class="article-shadow"></div>
                    <div class="content">

                    </div>
                </article>
                <div itemscope itemtype="http://schema.org/Product">

                    <div id="product-details-wrapper" >
                        <div class="inner-wrapper">
                            <div id="product-details">
                                <div class="container">
                                    <div class="content-wrapper">
                                        <div class="product-image col-sm-6">
                                            <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/carousels/applewoodbacon.png'} alt='Applewood Smoked Bacon' />
                                        </div>

                                        <div class="product-copy-container col-sm-6">
                                            <h1 itemprop="name">Applewood Smoked Bacon</h1>

                                            <div class="product-content">
                                                We smoke our thick-cut Applewood Smoked Bacon as long as needed to work the light, fruity and slightly sweet aroma of apple wood deep into the meat. We only stop when the cuts display a rich, reddish-brown color that signals they’ve been smoked to perfection.
                                    </div>

                                            <div class="col-md-7 col-sm-12 ratings-summary">
                                                <p class="summary-title">Average Customer Ratings</p>
                                                <div id="BVRRSummaryContainer" data-id="F3AB83D249974DA0B800731B5EC58EB5">
                                                </div>

                                            </div>

                                            <div class="col-md-5 col-sm-12 buy-link">
                                                <a href="/locator/?MM=panel2&amp;PROD=7962100001,7962100257" class="where-to-buy-link">
                                                    <span>Where to Buy</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div></div>

                                <div class="ratings-section expandable-section" id="reviews">
                                    <div class="title-wrapper">
                                        <div class="title-copy">
                                            <div class="title">Ratings &amp; Reviews</div>
                                        </div>
                                    </div>

                                    <div class="ratings-container panel" >
                                        <div class="panel-content">
                                            <div id="BVRRContainer" data-id="F3AB83D249974DA0B800731B5EC58EB5">
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="nutritional-section expandable-section">
                                    <div class="title-wrapper">
                                        <div class="title-copy">
                                            <div class="title">Nutritional Information</div>
                                        </div>
                                    </div>

                                    <div class="nutritional-container panel">
                                        <div class="panel-content">
                                            <div class="nutritionFacts">
                                                <div class="left">
                                                    <div class="title">Nutrition</div>
                                                    <p class="annotation">
                                                        *Percent daily values are based on a 2,000 calorie diet. Due to potential product reformulations, the most accurate information can be found on the product package.
                                            </p>
                                                </div>
                                                <div class="middle">
                                                    <div class="dvTitle bold">
                                                        <span class="hidden-xs hidden-sm">% Daily Value*</span>
                                                        <span class="hidden-md hidden-lg">%DV *</span>
                                                    </div>
                                                    <div class="borderBot4"></div>
                                                    <div id="nutrition-panel"></div>
                                                    <div class="nutrional-data-section">
                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Serving Size
                                                    </span>
                                                            <span class="amount pad-left">
                                                                1 Pan Fried Slices (14 g)
                                                    </span>
                                                        </div>

                                                        <div class="borderBot"></div>

                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Calories
                                                    </span>
                                                            <span class="amount">
                                                                80
                                                    </span>
                                                        </div>

                                                        <div class="borderBot"></div>

                                                        <div class="nutrional-data-item indent">
                                                            <span class="title">
                                                                Calories From Fat
                                                    </span>
                                                            <span class="amount">
                                                                60 g
                                                    </span>
                                                        </div>

                                                        <div class="borderBotIndent"></div>

                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Total Fat
                                                    </span>
                                                            <span class="amount">
                                                                7 g
                                                    </span>
                                                            <span class="percentage">
                                                                11 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBot"></div>

                                                        <div class="nutrional-data-item indent">
                                                            <span class="title">
                                                                Saturated Fat
                                                    </span>
                                                            <span class="amount">
                                                                2.0 g
                                                    </span>
                                                            <span class="percentage">
                                                                10 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBotIndent"></div>

                                                        <div class="nutrional-data-item indent">
                                                            <span class="title">
                                                                Trans Fat
                                                    </span>
                                                            <span class="amount">
                                                                0 g
                                                    </span>
                                                            <span class="percentage">
                                                                0 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBotIndent"></div>

                                                        <div class="nutrional-data-item">
                                                            <span class="title">
                                                                Cholesterol
                                                    </span>
                                                            <span class="amount">
                                                                mg
                                                    </span>
                                                            <span class="percentage">
                                                                %
                                                    </span>
                                                        </div>

                                                        <div class="borderBot4"></div>

                                                        <div class="nutrional-data-item-last">
                                                            <span class="title text-left">
                                                                Vitamin A
                                                        <span class="percentage">
                                                                    0 %
                                                        </span>
                                                            </span>

                                                            <div class="center-asterisk">*</div>

                                                            <span class="title text-right">
                                                                Vitamin C
                                                        <span class="percentage">
                                                                    0 %
                                                        </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="right">
                                                    <div class="dvTitle bold right-panel hidden-xs">
                                                        <span class="hidden-xs hidden-sm">% Daily Value*</span>
                                                        <span class="hidden-md hidden-lg">%DV *</span>
                                                    </div>

                                                    <div class="borderBot4"></div>

                                                    <div class="nutrional-data-section">
                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Sodium
                                                    </span>
                                                            <span class="amount">
                                                                250 mg
                                                    </span>
                                                            <span class="percentage">
                                                                10 %
                                                    </span>
                                                        </div>
                                                        <div class="borderBot"></div>
                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Total Carbohydrate
                                                    </span>
                                                            <span class="amount">
                                                                0 g
                                                    </span>
                                                            <span class="percentage">
                                                                0 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBot"></div>

                                                        <div class="nutrional-data-item indent">
                                                            <span class="title">
                                                                Dietary Fiber
                                                    </span>
                                                            <span class="amount">
                                                                0 g
                                                    </span>
                                                            <span class="percentage">
                                                                0 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBotIndent"></div>

                                                        <div class="nutrional-data-item indent">
                                                            <span class="title">
                                                                Sugars
                                                    </span>
                                                            <span class="amount">
                                                                0 g
                                                    </span>
                                                            <span class="percentage">
                                                                0 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBotIndent"></div>

                                                        <div class="nutrional-data-item">
                                                            <span class="title bold">
                                                                Protein
                                                    </span>
                                                            <span class="amount">
                                                                5 g
                                                    </span>
                                                            <span class="percentage">
                                                                10 %
                                                    </span>
                                                        </div>

                                                        <div class="borderBot4"></div>

                                                        <div class="nutrional-data-item-last">
                                                            <span class="title text-left">
                                                                Calcium
                                                        <span class="percentage">
                                                                    0 %
                                                        </span>
                                                            </span>

                                                            <div class="center-asterisk">*</div>

                                                            <span class="title text-right">
                                                                Iron
                                                        <span class="percentage">
                                                                    0 %
                                                        </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <article class="locator-callout">
                    <div class="quick-form-container">


                        <div class="product-locator-search">
                            <h2>Find Our Products</h2>
                            <form data-url="/locator/" action="/locator/" method="get">
                                <input value="panel2" id="MM" name="MM" type="hidden" />
                                <input Value="7962100001,7962100257" id="PROD" name="PROD" type="hidden" value="7962100001,7962100257" />
                                <div class="input-wrapper">
                                    <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                                    <input type="submit" value="Locate Bacon"></input>
                                    <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </Layout>
        )

    }
}
export default Applewood